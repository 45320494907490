<template>
    <div class="how-to-replenish-balance">
        <h1>How to replenish the balance of an advertising account?</h1>
        <p>
            It is possible to replenish the advertising account from two forms:
        </p>
        <ol>
            <li>The "Pay for advertising" button in the left block form</li>
            <li>The "Top up" button in the list of accounts</li>
        </ol>
        <ul>
            <li>Select the source of available balance (balance/cashback), select an advertising account from the list, fill
                in the amount, click the "Top up" button. The "Deposit" amount decreases, and the balance amount of the
                advertising account will increase by the filled amount.
            </li>
            <li>
                Balance actualization in the service interface depending on the platform can be up to 1 hour.
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "howToReplenishBalance",
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
        }
    }
}
</script>
<style lang="scss" scoped>
.how-to-replenish-balance {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h1 {
        line-height: normal;
    }

    ol,
    ul {
        list-style-position: inside;
    }
}
</style>